.admin-logo {
  width: 16rem;
  height: 100px !important;
}
.admin-logo-image{
  width: 7rem;
  height: 70px;
}

/* Styles for the table header */
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-header h3 {
  margin: 0;
  font-size: 24px;
}

.table-header .p-input-icon-left {
  position: relative;
}

.table-header .p-input-icon-left .pi {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #495057;
}

.table-header input[type="search"] {
  padding-left: 30px;
  width: 250px;
}

/* Styles for the create category button */
.create-category-button {
  margin-left: auto;
}

/* Styles for the table */
.p-datatable {
  width: 100%;
}

/* Styles for the pagination */
.p-paginator {
  margin-top: 20px;
  justify-content: flex-end;
}

/* Styles for the table rows */
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  vertical-align: middle;
}

/* Styles for the status */
.active-status {
  color: green;
}

.inactive-status {
  color: red;
}

/* Styles for the action dropdown */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown .dropdown-menu {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 5px 0;
  z-index: 1;
}

.dropdown .dropdown-menu a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}

.dropdown .dropdown-menu a:hover {
  background-color: #f9f9f9;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
}

.p-datatable .p-datatable-tbody > tr:last-child > td {
  border-bottom: none;
}

.p-datatable {
  width: 100%;
  border-collapse: collapse;
}

.p-datatable .p-datatable-thead > tr > th {
  border: 1px solid #dee2e6;
  text-align: left;
  vertical-align: middle;
  padding: 8px;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: 1px solid #dee2e6;
  text-align: left;
  vertical-align: middle;
  padding: 8px;
}

.p-datatable .p-datatable-tbody > tr > td:last-child {
  border-right: none;
}
.p-datatable .p-datatable-tbody > tr:last-child > td {
  border-bottom: none;
}
#colorPicker {
  height: 30px;
}

.content {
  flex: 1;
  padding-bottom: 60px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 10px;
  text-align: center;
}
.popup-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.thumbnail {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 10px;
}

.card-dropdown {
  position: absolute;
  top: 5px;
  right: 5px;
}

.dropdown-icon {
  cursor: pointer;
}

.dropdown-menu {
  min-width: 150px;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.pagination li.active a {
  font-weight: bold;
}

img {
  /* width: 100%; */
  max-width: 100%;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.position-relative {
  position: relative;
}

.mr-3 {
  margin-right: 1rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.popup-footer {
  margin-top: 1rem;
}

.active {
  color: green;
}

.inactive {
  color: red;
}

.ReactModal__Overlay--after-open {
  z-index: 9999 !important;
}

.text-danger {
  color: red;
}
.image-container {
  width: 100%;
  min-width: 150px;
  height: 150px;
  border: 1px solid rgb(221, 221, 221);
  padding: 5px;
  border-radius: unset;
  position: relative;
  background-color: transparent;
}
.ck-content {
  min-height: 200px;
}

.modal-content {
  background: linear-gradient(45deg, #db4c0a, #4d77ff);
  border-radius: 15px;
  padding: 20px;
  max-width: 1000px;
  min-width: 600px;
  max-height: 700px;
  min-height: 400px;
  overflow-y: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  border: 4px solid #e04912;
}

.delete-button {
  z-index: 10;
  border-radius: 50%;
  background-color: #ff4d4d;
  border: none;
  padding: 5px;
}

.draggable-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  border: 2px solid #ddd;
}

.draggable-image {
  position: relative;
  display: inline-block;
  cursor: move;
}

.nav-tabs .nav-item .nav-link {
  border-radius: 10px 10px 0 0;
  background-color: #f7f7f7;
  color: #555;
}

.nav-tabs .nav-item .nav-link.active {
  background-color: #63e6be;
  color: #fff;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  border: 2px solid #ddd;
  padding: 5px;
}

/* custom css section */

textarea {
  width: 100%;
  height: 40%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
}
#autoresizing {
  display: block;
  overflow: hidden;
  resize: none;
}

.create-css-button {
  margin-left: 95%;
}
.button-bg{
  background-color: #9f3838;
}
.error-message {
  color: red;
}
